import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import HelpIcon from '@mui/icons-material/Help';
import {useLocalStorage} from '../util/useLocalStorage';
import SelectWithLabel from '../util/SelectWithLabel';
import {propertyNameShort} from '../model/propertyTypes';
import HelpDialog from './editor/HelpDialog';
import {chooseTemplate, loadTemplates} from '../report/state';
import {setHighlight} from './state';


const TemplateChooser = ({comp}) => {
  const dispatch = useDispatch();
  const templates = useSelector(state => state.report.templates.all);
  const template = useSelector(state => state.report.templates.curr);
  const [showAllTemplates, setShowAllTemplates] = useLocalStorage("quickcomp.compEditor.showAllTemplates", false);
  const [highlightFields, setHighlightFields] = useLocalStorage("quickcomp.compEditor.highlightFields", false);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (!templates || !templates.length) {
      dispatch(loadTemplates())
          // .catch(setError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templates && templates.length > 0 &&
        (!(template||{}).id || (!showAllTemplates && !template.types.includes(comp.type)))) {
      const t = templates.find(it => it.types?.includes(comp.type) === true);
      if (t) {
        dispatch(chooseTemplate(t));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, template]);

  const toggleHighlight = () => {
    setHighlightFields(!highlightFields);
    dispatch(setHighlight(!highlightFields));
  }

  return (
      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
        <Tooltip title="Refresh Template list">
          <IconButton size="small">
            <RefreshIcon/>
          </IconButton>
        </Tooltip>
        <SelectWithLabel label="Template" value={template.id} fullWidth={false} size="small"
                         onChange={evt => dispatch(chooseTemplate(templates.find(it => it.id === evt.target.value)))}
                         values={(showAllTemplates ? templates : templates.filter(t => t.types?.includes(comp.type) === true)).map(t => [t.id, t.title])}/>
        <Tooltip title={showAllTemplates ? `Show only Templates for ${propertyNameShort(comp.type)}` : `Show all Templates`}>
          <ToggleButton value="filter" size="small" selected={!showAllTemplates} onChange={() => setShowAllTemplates(!showAllTemplates)}>
            {showAllTemplates ? <FilterListOffIcon/> : <FilterListIcon/>}
          </ToggleButton>
        </Tooltip>
        <Tooltip title={`Turn ${highlightFields?'off':'on'} Highlight Fields from Template`}>
          <ToggleButton value="highlight" size="small" selected={!highlightFields} onChange={toggleHighlight}>
            {highlightFields ? <FlashlightOffIcon/> : <FlashlightOnIcon/>}
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Save Changes">
          <IconButton onClick={() => setShowHelp(!showHelp)} size="large">
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <HelpDialog title="Template Chooser" open={showHelp} onClose={() => setShowHelp(false)}>
          <Typography>
            The <em>Template</em> drop down allows you to select a template to use while editing the comp. This is useful for
            several reasons, including:
          </Typography>
          <Typography variant="h5">
            Preview
          </Typography>
          <Typography>
            The Preview function will use this template to render a sample report with the comp. This is useful for
            verifying how the comp will display in an output report.
          </Typography>
          <Typography variant="h5">
            Data Input
          </Typography>
          <Typography>
            Choosing a template during data input identifies the fields of the comp that will be used for the output report. This
            is useful for ensuring that all necessary data used by the report is entered for the comp. Fields used by the report
            template are identified with a red dot.
          </Typography>
        </HelpDialog>
      </Stack>
  )
}

export default TemplateChooser;
