import { combineReducers } from "redux";
import {configureStore} from '@reduxjs/toolkit'
import cartMiddleware from "./cart/middleware";
import {firebaseErrorMiddleware} from "./firebase";
import app from './app/state';
import auth from './auth/state';
import comp from './comp/state';
import dash from './dash/state';
import cart from './cart/state';
import report from './report/state';
import find from './find/state';
import sheet from './sheet/state';
import list from './list/state';
import pref from './pref/state';
import master from './master/state';
import signup from './signup/state';
import profile from './profile/state';
import template from './template/state';
import search from './search/state';

export default configureStore({
  reducer: combineReducers({
    app, auth, comp, dash, cart, report, find, sheet, list, pref, master, signup, profile, template, search
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   isSerializable: (v) => {
      //     if (firebaseSerializableCheck(v)) return true;
      //     if (v instanceof Field) return true;
      //     return isPlain(v);
      //   }
      // }
    }).concat(firebaseErrorMiddleware).concat(cartMiddleware)
});
