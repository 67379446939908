import React, {Fragment, useState} from "react";
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {logout} from '../auth/state';
import {UserAvatar} from "../util/UserAvatar";


const UserMenu = () => {
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout())
          .then(() => history.replace('/'));
    }

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    return (
        <Fragment>
            <UserAvatar user={user} aria-describedby={id} onClick={handleClick}/>
            <Menu
                id={id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">{user.email}</Typography>
                </MenuItem>
                <MenuItem component={Link} to="/profile">
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">Logout</Typography>
                </MenuItem>
            </Menu>
        </Fragment>
    );
};


export default UserMenu;
