import React, {Fragment, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import HorizontalRadioGroup from "../util/HorizontalRadioGroup";
import {MapWithMarker} from "../util/Map";
import {AddMarkerControl} from "../comp/AddressStep";
import {setHasSubject, setSubject} from './state';
import PlaceInput from '../comp/GooglePlaceInput';
import {isNil, omitBy} from 'lodash';


const SubjectStep = () => {
    const org = useSelector(state => state.app.org);
    const hasSubject = useSelector(state => state.report.curr.hasSubject);
    const subject = useSelector(state => state.report.curr.subject || {});
    const dispatch = useDispatch();
    const attributionRef = useRef();

    React.useEffect(() => {
        if (dispatch) dispatch(setSubject(null));
    }, [dispatch]);

    const handleChange = evt => dispatch(setHasSubject(evt.target.value === "true"));
    const handlePlace = p => dispatch(setSubject({
        geo: p.center,
        title: p.street,
        address: omitBy({
            street: p.street,
            city: p.place,
            // subtitle: `${p.place}, ${p.region}`
        }, isNil),
        // subtitle: `${p.place}, ${p.region}`
    }));

    const handleGeoChange = ll => {
        dispatch(setSubject({
            ...subject,
            geo: ll.toArray()
        }));
    };

    const initMap = (map) => {
        map.addControl(new AddMarkerControl(g => dispatch(setSubject({
            ...subject,
            geo: g,
        }))));
    };

    const mapOpts = {
        ...(org.settings||{}).map,
        initMap
    };

    return (
        <Box className="SubjectStep">
            <Typography variant="subtitle1" sx={{mb: 8, textAlign: "center"}}>
                A subject for the report is optional. When a subject is specified it is used in the "Comp Map" section
                of the report.
            </Typography>
            <HorizontalRadioGroup value={hasSubject}  onChange={handleChange} sx={{margin: "auto", mb: 4}}>
                <FormControlLabel value={false} control={<Radio color="primary"/> } label="No, this report has no subject"/>
                <FormControlLabel value={true} control={<Radio color="primary"/>} label="Yes I would like to choose a subject" />
            </HorizontalRadioGroup>
            <Stack sx={{mb: 2}}>
                {hasSubject && attributionRef.current && <PlaceInput attributionEl={attributionRef.current} onPlace={handlePlace}/>}
                <div ref={attributionRef}></div>
            </Stack>
            {hasSubject && <Fragment>
                <Box sx={{mt: 1, height: "384px"}}>
                    <MapWithMarker geo={subject.geo} onGeoChange={handleGeoChange} {...mapOpts}/>
                </Box>
            </Fragment>}
        </Box>
    )
};

export default SubjectStep;

